import React, { Component } from 'react'
import './styles.scss'

class FixedBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      srcLogo: '/resolute-logo-light.svg'
    }
  }
  render() {
    const {srcLogo} = this.props
    
    return (
      <div className='fixed-viewport'>
        <div className='fixed-title'>
          <div className='logo'><img src={srcLogo} alt='ResoluteSoftware' /></div>
          <h1>Front-End Engineer in Web</h1>
        </div>
      </div>
    )
  }
}

export default FixedBox