import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FixedBox } from '../../components'
import './styles.scss'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offsetY: 0,
      screenHeight: 0
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll = () => {
    let scrollY = this.state.offsetY
    scrollY = window.scrollY;
    this.setState({ offsetY: scrollY });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {

    return (
      <div className='home'>

        <section className='section-1'>
          <FixedBox srcLogo='/resolute-logo-light.svg' />
          <div className='parallax-bg' id='parallax-bg-1' style={{ transform: `translateY(${this.state.offsetY * 1.5}px)` }} />
        </section>

        <section className='section-2'>
          <FixedBox srcLogo='/resolute-logo-dark.svg' />
        </section>

      </div>
    )
  }
}

export default connect()(Home)
