import React, { Component } from 'react'
// import {connect} from 'react-redux'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Home } from '../../screens'

class Routes extends Component {
  render() {
    return (
      <div className='wrapall'>
        <Switch>
          <Route exact path='/' component={Home} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Routes)